import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { ExternalNavBarDesktop_UserFragment } from 'generated/graphql'
import AppInstallQRCode from '@app.components/AppInstallQRCode/AppInstallQRCode'
import {
  ACTIVITY_BOT,
  CONTEST_BOT,
  COVERLETTER_BOT,
  RECRUIT_BOT,
  SPECUP_CAFE,
} from 'constants/urls'
import CafeIcon from 'svgIcons/Cafe'
import TalkIcon from 'svgIcons/Talk'
import UserAction from '../UserAction/UserAction'

gql`
  fragment ExternalNavBarDesktop_user on User {
    ...UserAction_user
  }
`

const EXTERNAL_NAV_BAR_MENU_LIST = [
  {
    label: '스펙업',
    href: SPECUP_CAFE,
    icon: <CafeIcon />,
  },
  {
    label: '대외활동봇',
    href: ACTIVITY_BOT,
    icon: <TalkIcon />,
  },
  {
    label: '공모전봇',
    href: CONTEST_BOT,
    icon: <TalkIcon />,
  },
  {
    label: '채용봇',
    href: RECRUIT_BOT,
    icon: <TalkIcon />,
  },
  {
    label: '자소서봇',
    href: COVERLETTER_BOT,
    icon: <TalkIcon />,
  },
]

interface IFExternalNavBarDesktopProps {
  currentUser?: ExternalNavBarDesktop_UserFragment | null
  loading?: boolean
}

const ExternalNavBarDesktop: FC<IFExternalNavBarDesktopProps> = ({
  currentUser,
  loading,
}) => {
  return (
    <StyledWrapper>
      <div className="external-nav-container">
        <nav>
          <AppInstallQRCode />
          {EXTERNAL_NAV_BAR_MENU_LIST.map((item) => (
            <a
              className="menu-item"
              key={item.label}
              href={item.href}
              rel="noopener noreferrer"
            >
              {item.icon}
              {item.label}
            </a>
          ))}
        </nav>
        <UserAction currentUser={currentUser} loading={loading} />
      </div>
    </StyledWrapper>
  )
}

export default ExternalNavBarDesktop

const StyledWrapper = styled.div`
  border-bottom: 1px solid #ebebeb;

  .external-nav-container {
    width: 100%;
    max-width: 1148px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 24px;

    & > nav {
      display: flex;
      align-items: center;
      gap: 24px;

      .menu-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #999;
        gap: 5px;
        svg {
          width: 17px;
        }
      }
    }
  }
`
