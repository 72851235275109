import { FC, useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { UserType } from 'generated/graphql'
import { CAREER_MANAGEMENT_PATH_NAME } from 'app.constants/careerPathname'
import {
  ACTIVITIES_TAB,
  NAV_BAR_MENU_ITEMS,
  NavBarActiveKey,
  NavBarBadge,
  NavBarType,
  TNavBar,
} from 'app.layout/constant/pageLayout.type'
import CoverLetterCount from './components/CoverLetterCount'
import NavBarLinkWithIcon from './components/NavBarLinkWithIcon'
import NewBadge from './components/NewBadge'
import SpecManageButton from './components/SpecManageButton'

interface IFProps extends TNavBar {
  isLogin: boolean
  userType?: UserType
}

const NavBarHoverMenu = dynamic(() => import('./components/NavBarHoverMenu'), {
  ssr: false,
})

const NavBarDesktop: FC<IFProps> = ({
  isLogin,
  activeKey,
  userType,
  isSticky = false,
}) => {
  const [isStucked, setIsStucked] = useState(false)
  const navbarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsStucked(e.intersectionRatio < 1)
      },
      { threshold: [1] },
    )
    if (isSticky && navbarRef.current) {
      observer.observe(navbarRef.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [isSticky, navbarRef])

  const getIsActivitiesTab = (active: NavBarActiveKey) => {
    return ACTIVITIES_TAB.includes(activeKey) && ACTIVITIES_TAB.includes(active)
  }

  const careerManagementUrl = isLogin
    ? `${CAREER_MANAGEMENT_PATH_NAME}?section=info`
    : `/login?next=${encodeURIComponent(
        `${CAREER_MANAGEMENT_PATH_NAME}?section=info`,
      )}`

  return (
    <StyledWrapper isSticky={isSticky}>
      <div className="nav-container">
        <nav
          data-activity-type={
            activeKey === NavBarActiveKey.ROOT ? 'tab-root' : ''
          }
        >
          <ul className="nav-list">
            {NAV_BAR_MENU_ITEMS.map((menuItem) => (
              <li key={menuItem.label} className="nav-list-item">
                <Link href={menuItem.href}>
                  <a
                    className="menu-button"
                    data-active={
                      getIsActivitiesTab(menuItem.active) ||
                      activeKey === menuItem.active
                    }
                  >
                    {menuItem.label}
                    {menuItem.badge === NavBarBadge.NEW && <NewBadge />}
                    {menuItem.badge === NavBarBadge.COUNT && !isStucked && (
                      <CoverLetterCount />
                    )}
                  </a>
                </Link>
                {menuItem.type === NavBarType.HOVER && (
                  <NavBarHoverMenu
                    activeKey={activeKey}
                    menuItems={menuItem.menuItems}
                    isActivitiesTab={getIsActivitiesTab(menuItem.active)}
                    isChannelTabActive={
                      activeKey === NavBarActiveKey.CHANNEL &&
                      menuItem.active === activeKey
                    }
                  />
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="nav-link-icon">
          <SpecManageButton
            activityHref={careerManagementUrl}
            activityLabel="스펙 정리하기"
            userType={userType}
            {...(!isLogin && {
              as: '/login',
            })}
          />
          <NavBarLinkWithIcon
            activityHref="/manage/welcome"
            activityLabel="공고등록"
          />
        </div>
      </div>
    </StyledWrapper>
  )
}

export default NavBarDesktop

type TStyleProps = {
  isSticky: boolean
}

const StyledWrapper = styled.div<TStyleProps>`
  ${({ theme, isSticky }) => css`
    position: ${isSticky ? 'sticky' : 'relative'};
    top: -1px;
    z-index: 4;
    background-color: ${theme.background.bg_8};
    box-shadow: 0 9px 9px -4px rgba(170, 170, 170, 0.16);
    border-top: 1px solid #eeeeee;

    .nav-container {
      width: 100%;
      max-width: 1148px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      padding: 0 24px;

      & > nav {
        &[data-activity-type='tab-root'] {
          & > .nav-list > .nav-list-item {
            display: block;
          }
        }

        & > .nav-list {
          display: flex;
          gap: 32px;
          flex-shrink: 0;

          & > .nav-list-item {
            display: inline-flex;

            .menu-button {
              height: 60px;
              font-size: 17px;
              font-weight: bold;
              line-height: 25px;
              letter-spacing: -0.68px;
              text-align: left;
              color: ${theme.color.text_1};
              display: inline-flex;
              justify-content: center;
              align-items: center;
              padding: 8px 0;

              &[data-active='true'] {
                color: ${theme.color.primary_2};
                font-weight: bold;
              }
            }

            .root-dropdown {
              display: none;
              z-index: 11;
            }

            .second-depth-nav {
              display: none;
              z-index: 3;

              &[data-activity='true'] {
                display: block;

                & > div {
                  box-shadow: inset 0 6px 9px -7px rgba(170, 170, 170, 0.16);
                }
              }
            }

            &:hover {
              .menu-button {
                color: ${theme.color.primary_2};
                background-color: transparent;
                font-weight: bold;
              }

              .root-dropdown {
                display: block;
              }

              .second-depth-nav {
                display: block;
                z-index: 4;

                &:not([data-activity='true']) > div::after {
                  content: '';
                  width: 100%;
                  height: 30px;
                  box-shadow: 0 3px 13px 0 rgba(153, 153, 153, 0.16);
                  position: absolute;
                  bottom: 0;
                  z-index: -1;
                }

                &[data-channel='true'] {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .nav-link-icon {
      display: flex;
      align-items: center;
    }
  `}
`
