import { FC } from 'react'
import styled from 'styled-components'

const FooterCompanyInfo: FC = () => {
  return (
    <StyledWrapper>
      <span>사업자 등록번호 : 491-81-03390</span>
      <span>직업정보제공 사업신고번호 : J1200020240012</span>
      <span>통신판매업 신고번호 : 제 2024-서울강남-04747호</span>
      <br />
      <span>주식회사 링커리어 대표이사 : 노은돈</span>
      <span>서울특별시 강남구 역삼로3길 11 (역삼동)</span>
      <br />
      <span>
        Copyright © <b>Linkareer Inc</b>. All Rights Reserved.
      </span>
    </StyledWrapper>
  )
}

export default FooterCompanyInfo

const StyledWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
  color: #999;
  margin-top: 30px;
  span {
    margin: 5px 20px 0 0;
  }
`
