import { FC } from 'react'
import styled from 'styled-components'
import useFooterItems from '../module/useFooterItems'

const FooterMenuList: FC = () => {
  const { footerItems } = useFooterItems()

  return (
    <StyledWrapper>
      {footerItems.map((item, itemIndex) => (
        <div key={`${item.title}-id-${itemIndex}`} className="menu-list">
          <div className="menu-item">{item.title}</div>
          <div className="sub-menu-list">
            {item.items.map((subItem, subItemIndex) =>
              subItem.href ? (
                <a
                  key={`${subItem.label}-id-${subItemIndex}`}
                  href={subItem.href}
                  onClick={subItem.onClick}
                  className="sub-menu-item"
                  target={subItem.target}
                >
                  • {subItem.label}
                </a>
              ) : (
                <span
                  key={`${subItem.label}-id-${subItemIndex}`}
                  onClick={subItem.onClick}
                  className="sub-menu-item"
                >
                  • {subItem.label}
                </span>
              ),
            )}
          </div>
        </div>
      ))}
    </StyledWrapper>
  )
}

export default FooterMenuList

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .menu-list {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .menu-item {
      height: 30px;
      font-size: 12px;
      font-weight: bold;
    }
    .sub-menu-list {
      display: flex;
      flex-direction: column;
      gap: 7px;
      .sub-menu-item {
        font-size: 12px;
        font-weight: 500;
        background-color: #fff;
        padding: 0;
      }
    }
  }
`
